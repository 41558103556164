import UserKey from '../UserKey'
import schttp from 'public/src/services/schttp'
import { USERINFO_KEY } from '@login/common/contants.js'
import { setLocalStorage, getLocalStorage, removeLocalStorage } from '@shein/common-function'

const STORAGE_KEY = 'UserInfo'

const CACHE_KEY = USERINFO_KEY

const CLIENT_STORAGE_EXPIRE = 18 * 24 * 60 * 60 * 1000
class UserInfo extends UserKey {
  static canHandle(key) {
    return key === STORAGE_KEY
  }

  async getAuthUserInfo () {
    const data = await schttp({
      url: '/api/auth/authUserInfo/get',
    })
    const { code, info } = data || {}
    if (code == 0) {
      return info || {}
    }
  }

  async get () {
    if (!this.isLogin()) {
      removeLocalStorage(CACHE_KEY)
      return {}
    }
    let result = {}
    result = getLocalStorage(CACHE_KEY) 
    if (!result) {
      result = await this.getAuthUserInfo()
      setLocalStorage({
        key: CACHE_KEY,
        value: result,
        expire: CLIENT_STORAGE_EXPIRE,
      })
    }
    return result
  }

  set (value, { } = {}) {
    const { AuthToken } = value
    const params = { AuthToken }
    setLocalStorage({
      key: CACHE_KEY,
      value: params,
      expire: CLIENT_STORAGE_EXPIRE,
    })
  }

  getSync () {
    if (!this.isLogin()) {
      removeLocalStorage(CACHE_KEY)
      return {}
    }
    const data = getLocalStorage(CACHE_KEY)
    return data || {}
  }
}

export default UserInfo

