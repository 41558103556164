// 首页配置

export default {
  pageName: 'page_home',
  newPosKeys: [
    'HomePageTerminal',
    'HomeEstimatedPrice',
    'FSEstimatedPrice',
    'EstimatedPrice',
    'CouponBlock',
    'BGEstimatedPrice',
    'HomePageFloatingIconExp',
    'advertising',
    'CCCHomePageDynamic',
    'FlashSaleCategory',  
    'HomeIconIfRec',
    'HomeIconRecImageType',
    'HomeItemRec',
    'HomePopup'  
  ],
  posKeys: [
    'CccAppIcon',
    'WishlistEmptyRecommend',
    'WishlistRecommend',
    'WishlistRecommendationsForYou',
    'AllListQuickShip',
    'newPrivacy',
    'Movepoints',
  ],
}
