import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { linkTo } from 'public/src/pages/components/ccc/common/utils.js'
// 业务监控
import HomeMonitor from 'public/src/pages/common/home_common/monitor.js'
import { metricCccClick } from 'public/src/pages/common/business-monitor/common.js'

export default {
  data() {
    return {
      cccLink: null
    }
  },
  provide() {
    return {
      isFirstPage: this.lazyForceShow,
    }
  },
  inject: {
    metricsConfig: {
      from: 'metricsConfig',
      default: () => {},
    },
  },
  computed: {
    metaData() {
      return this.propData?.props?.metaData || {}
    },
    code() {
      const { componentKey, id } = this.propData
      return `${componentKey}${this.index}${id}`
    },
    mixinOpenTarget({ sceneData }) {
      if (sceneData?.pageType === 'storeHomePage' && sceneData?.isBrandStore) {
        return '_blank'
      }
      return '_self'
    }
  },
  created() {
    this.cccLink = new CccLink({
      sceneData: this.sceneData,
      propData: this.propData,
      context: this.context,
    })
  },
  methods: {
    getAnalysisData(id, { item, index = 0, ...arg } = {}, setCode = false) {
      const data = {
        sceneData: this.sceneData,
        propData: this.propData,
        compIndex: this.index,
        item,
        index,
        ...arg
      }

      let code = ''
      if (typeof setCode === 'boolean' && setCode) code = this.code
      if (typeof setCode === 'string') code = setCode

      return {
        id,
        code,
        data
      }
    },

    /**
     * 获取商品类埋点信息
     * @param {*} cate ccc配置的内容，包含hrefType, hrefTarget这些内容
     * @param {*} tab tab的信息，包含，tab的位置index，cateType(tab内容类型)，cateId(tab id)
     * @returns
     */
    getProductAnalysis(id, { type = 'click', index, item, cate, tab, page, flash_type, poskey, isJumpToList, isPopup, isNew, act_nm, extendSaParams }) {
      return this.getAnalysisData(id, {
        item: cate,
        index,
        product: item,
        tab: tab,           // 当前的tab
        page, // 第几页
        flash_type,
        poskey,
        isJumpToList, // 是否是商品跳列表页面
        isPopup,
        isNew,
        act_nm,
        extendSaParams
      }, type === 'expose' ? `${this.code}_product` : '')
      // type 为曝光的时候 需要给埋点信息加上唯一code (商品类)
    },

    // 跳转落地页通用方法
    handleClickToPage({ hrefType, hrefTarget, itemIndex, poskey, relativeUrl = '', adp = '' }, needAnalysis = true) {
      const url = this.cccLink.getFullLink({
        item: {
          hrefType,
          hrefTarget,
          ...(relativeUrl ? { relativeUrl } : {}),
          ...(adp ? { adp } : {}),
        },
        index: itemIndex,
        cateLinks: this.cateLinks,
        compIndex: this.index,
      })
      console.log('click url', url, hrefType, hrefTarget, this.cateLinks,)
      needAnalysis && this.sendAnalysis({
        hrefType,
        hrefTarget,
        itemIndex,
        poskey,
      })
      if (!url || url.indexOf('javascript:;') > -1) return
      if (hrefType === 'sku') { 
        this.mixinLinkTo(url)
        return
      }
      location.href = url
    },
    sendAnalysis({ hrefType, hrefTarget, itemIndex, poskey }) {
      daEventCenter.triggerNotice({
        daId: '2-19-2',
        bindData: {
          sceneData: this.sceneData,
          propData: this.propData,
          compIndex: this.index,
          index: itemIndex,
          poskey,
          item: {
            hrefType,
            hrefTarget,
          },
        },
      })
    },
    // 跳转通用方法
    mixinLinkTo(url, openTarget) {
      const openTargetParam = openTarget ? openTarget : this.mixinOpenTarget
      try {
        // 点击CCC 组件监控
        if (this.metricsConfig?.reportMetrics?.cccClick) {
          metricCccClick({ 
            page: this.metricsConfig.pageName,
            component_type: this.propData?.styleType,
            position: `${this.index + 1}`,
          })
        }
      } finally { 
        linkTo(url, openTargetParam)
      }
    },
    // 隐藏组件告警
    hideCompMonitor(params = {}) {
      if (!this.metricsConfig?.reportMetrics?.hideCompMonitor) return
      let info = {
        tags: {
          page: this.metricsConfig.pageName || '',
          component_type: this.propData?.styleType || '',
          reason: params?.tags?.reason || '',
        },
        message: params?.message || ''
      }
      HomeMonitor.metricCompFilter(info)
    },
    // 组件点击告警
    clickCompMonitor() {
      if (!this.metricsConfig?.reportMetrics?.cccClick) return
      metricCccClick({
        page: this.metricsConfig.pageName || '',
        component_type: this.propData?.styleType || '',
        position: `${this.index + 1}`,
      })
    },
  },
}
