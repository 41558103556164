import { parseQueryString } from '@shein/common-function'

const { IS_RW, PICKS_LINKS } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}
const reg = /(?:\/([a-z]+-picks|romwe-[a-z]+|promo-discount))\.html/

/**
 * 非空对象
 * @param {*} obj 
 * @returns 
 */
function isNonEmptyObject(obj) {
  return obj !== null && typeof obj === 'object' && Object.keys(obj).length > 0
}

/**
 * romwe/shein pick 路由转换
 * @description 根据 hrefTarget 将原始链接转换为特定的链接
 * @param {String} url 原始链接
 * @param {Object} links 服务端渲染时传递过来的PICKS_LINKS，用于此函数可以直出执行不受gbCommonInfo的限制
 * @return {String} 结果链接
 */
export function transformPromodiscountUrl(url, links = {} ) {
  if (typeof window === 'undefined' && !isNonEmptyObject(links)) return url
  const LINKS = (typeof window === 'undefined') ? links : PICKS_LINKS
  const query = url.split('?')[1]
  const match = url.match(reg)?.[0]
  if (!query || !match) return url

  const newURL = LINKS[parseQueryString(query).hrefTarget] || (IS_RW ? 'promo-discount' : 'shein-picks')
  url = url
    .replace(match, `/${newURL}.html`)
    .replace(/[?&]?hrefTarget=\d+/, '')
  return url
}
