
//google自动登录冷却期缓存
export const DISABLE_AUTO_SELECT = 'disableAutoSelect'

//google自动登录冷却时长24小时
export const DISABLE_AUTO_SELECT_TIME = 24 * 3600 * 1000

export const MESSENGER_PAGE_ID = {
  'us': '124739210717984', // 正式的：121041328593190 测试环境：124739210717984
  'ph': '103863481839366',
  'au': '647306098990385',
  'ca': '102143945750425',
}

export const USERINFO_KEY = 'auth_member'
