<!-- eslint-disable @shein-aidc/abt/abt -->
<template>
  <div>
    <!-- Messenger 聊天插件 Code -->
    <div
      id="fb-root"
      :style="{display: hideFb}"
    ></div>
    <!-- Your 聊天插件 code -->
    <div
      id="fb-customer-chat"
      class="fb-customerchat"
    >
    </div>
    <div
      class="c-back-wrap j-back-wrap"
      :class="[`${lang}-c-back-wrap`]"
    > 
      <!-- facebook-messenger-icon S -->
      <div class="messengerBox">
        <s-popover
          v-if="isShowFacebookMessenger && fbIconWrapShow && fbRenderSucc"
          :class="'fb-messenger-popover'"
          trigger="hover"
          placemen="left-end"
          :append-to-body="false"
          :prop-style="{
            maxWidth: '261px',
            padding: '0px',
            borderRadius: '8px',
            boxShadow: '0px 1px 6px 0px rgba(0, 0, 0, 0.1)'
          }"
          @click.stop
        >
          <div
            v-show="isShowPopover"
            class="c-fb-popover"
          >
            <img
              :src="`${PUBLIC_CDN}/she_dist/images/fb-messenger/messenger-icon-8f633138ac.png`"
              alt="right-icon"
              class="c-fb-popover__logo"
            />
            <h1 class="c-fb-popover__title"> 
              {{ language.SHEIN_KEY_PC_26807 }}
            </h1>
            <div class="c-fb-popover__desc">
              <div class="c-fb-popover__item">
                <img
                  class="c-fb-popover__icon"
                  :src="`${PUBLIC_CDN}/she_dist/images/fb-messenger/account-f3fd269cd3.png`"
                  alt="account"
                />
                <div class="c-fb-popover__text">
                  {{ language.SHEIN_KEY_PC_26809 }}
                </div>
              </div>
              <div class="c-fb-popover__item">
                <img
                  class="c-fb-popover__icon"
                  :src="`${PUBLIC_CDN}/she_dist/images/fb-messenger/messenger-1c5de21770.png`"
                  alt="messenger"
                />
                <div class="c-fb-popover__text">
                  {{ language.SHEIN_KEY_PC_26810 }}
                </div>
              </div>
            </div>
            <div
              class="c-fb-popover__btn"
              @click="fbSdkShow('detail')"
            >
              {{ language.SHEIN_KEY_PC_26808 }}
              <img
                v-if="!fbSdkLoading"
                :src="`${PUBLIC_CDN}/she_dist/images/fb-messenger/right-4b55a41d35.png`"
                alt="right-icon"
                class="c-fb-popover__right"
              />
              <CircleLoading 
                v-else 
                :show="true"
                style="margin-left: 4px"
              />
            </div>
          </div>
          <template #reference>
            <div
              id="j-fb-warpIcon"
              class="c-fb-popover__wrapIcon"
              @click="fbSdkShow('icon')"
              @mouseenter="enterWrapIcon"
            >
              <img
                v-if="!isLoding"
                :src="`${PUBLIC_CDN}/she_dist/images/fb-messenger/messenger-icon-8f633138ac.png`"
                alt="fb-logo"
              />
              <CircleLoading 
                v-else
                :show="true"
                style="margin-left: 12px"
              />
            </div>
          </template>
        </s-popover>
      </div>
      <!-- facebook-messenger-icon E -->
      <div
        v-if="LAYOUT == 'layout' && iconDataSource == 'ccc' && !floatingIconPreview"
        class="suspension-iconWrap"
      >
        <suspension-icon />
      </div>
      <!-- 悬浮组件New -->
      <div
        v-if="(iconDataSource == 'cccx' && IfShowComponent) || floatingIconPreview" 
        class="floating-iconWrap"
      >
        <floatingIcon />
      </div>
      <div
        v-if="showQuestionnaire"
        class="show-questionnaire j-show-questionnaire"
        style="display: none;"
      >
        <Icon
          name="sui_icon_sverycenter_24px_1"
          size="24px"
        />
        <div class="show-questionnaire__info she-hide">
          <h4 class="title">
            <span class="txt"></span>
            <Icon
              name="sui_icon_left_48px_1"
              size="30px"
            />
          </h4>
          <p class="desc"></p>
          <img
            class="info_bg"
            :src="`${PUBLIC_CDN}/she_dist/images/qustionnaire_popup-19c3d203ec.png`"
            width="auto"
            height="100%"
            alt=""
          />
        </div>
      </div>
      <div
        v-if="showApp"
        class="back-wrap-show"
      >
        <div
          class="back-app coupon-icon"
          @mouseover="handleMouseOver"
        >
          <img
            :src="couponIconSrc"
            alt=""
          />
        </div>
        <!-- 如果ccc配置了权益弹窗，优先展示ccc的弹窗配置-->
        <right-config-dialog
          v-show="cccRightConfigImgUrl"
          :ccc-right-config-img-url="cccRightConfigImgUrl"
        />
            
        <div
          v-if="!cccRightConfigImgUrl"
          class="show-info"
        >
          <div
            class="show-info-ctn"
          >
            <h4>{{ language.SHEIN_KEY_PC_15764 }}</h4>
            <template v-if="SiteUID != 'hk'">
              <div
                v-if="SiteUID === 'us'"
                class="show-info-txt" 
              >
                <p class="to-enjoy">
                  <span> {{ language.SHEIN_KEY_PC_18487 }}</span>
                </p>
                <p
                  class="er-percent"
                  :class="[['ma', 'marar'].includes(lang) ? 'er-percent-ma' : '' ]"
                >
                  <!-- 修改SHEIN-pc-EN+ES 的百分比-->
                  <span
                    class="percent-first j-percent-first"
                    v-html="template(['uses', 'us'].includes(lang)?'20%':'15%' || '', language.SHEIN_KEY_PC_18479)"
                  >
                  </span>
                  <span
                    v-if="['cl', 'arg'].includes(lang)"
                    class="percent-sec"
                    style="right: 10px;"
                    v-html="language.SHEIN_KEY_PC_18477"
                  ></span>
                  <span
                    v-else
                    class="percent-sec"
                    v-html="language.SHEIN_KEY_PC_18477"
                  ></span>
                </p>
                <p class="code-app">
                  <span
                    class="j-code-coupon"
                    v-html="template(['uses', 'us'].includes(lang)?'APPONLY20':'APPONLY' || '', language.SHEIN_KEY_PC_18478)"
                  ></span>
                </p>
              </div>
              <div 
                v-else-if="showFirstCouponInfo" 
                class="show-info-txt"
              >
                <p class="to-enjoy">
                  <span> {{ language.SHEIN_KEY_PC_15765 }}</span>
                </p>
                <p
                  class="er-percent"
                  :class="[['ma', 'marar'].includes(lang) ? 'er-percent-ma' : '' ]"
                >
                  <span
                    class="percent-first j-percent-first"
                    v-html="template((filterSite() ? firstOrderCouponInfo.info : firstCouponSite.info) || '', language.SHEIN_KEY_PC_15766)"
                  >
                  </span>
                  <span
                    v-if="['cl', 'arg'].includes(lang)"
                    class="percent-sec"
                    style="right: 10px;"
                    v-html="language.SHEIN_KEY_PC_15820"
                  ></span>
                  <span
                    v-else
                    class="percent-sec"
                    v-html="language.SHEIN_KEY_PC_15820"
                  ></span>
                </p>
                <p class="code-app">
                  <span
                    class="j-code-coupon"
                    v-html="template((filterSite() ? firstOrderCouponInfo.coupon : firstCouponSite.coupon) || '', language.SHEIN_KEY_PC_15767)"
                  ></span>
                </p>
              </div>
            </template>
            <div class="j-download-warp">
              <div class="qr-code">
                <img class="j-verlok-lazy lazyload" />
              </div>
              <div class="link-list">
                <a
                  class="android-a j-app-info j-new-app-info-android"
                  data-type="Android"
                  target="_blank"
                >
                  <Icon
                    name="sui_icon_andriod_28px"
                    size="28px"
                  />
                  Android
                </a>
                <a
                  class="apple-a j-app-info j-new-app-info"
                  data-type="IOS"
                  target="_blank"
                >
                  <Icon
                    name="sui_icon_apple_28px"
                    size="28px"
                  />
                  iPhone
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-expose="getAnalysisData('2-15-22', { }, 'backtopExpose')"
        da-expose-code="backtopExpose"
        class="c-uptt j-uptt"
      >
        <Icon
          name="sui_icon_more_up_24px_1"
          size="24px"
          style="color: #fff"
        />
      </div>
    </div>
    <div
      v-if="showMore && ['us', 'uk'].includes(lang)"
      class="c-video she-mask-white j-video-mask"
    >
      <div class="video-wrap2">
        <i class="content-close j-hide-video">
          <Icon
            name="sui_icon_closed_14px_1"
            size="14px"
          />
        </i>
        <div class="video-wrap j-video-wrap">
          <div id="ftplayer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import schttp from 'public/src/services/schttp'
import md5 from 'md5'
import suspensionIcon from '../../components/suspension_icon/suspension_icon.vue'
import floatingIcon from 'public/src/pages/components/floatingIcon/floatingIcon.vue'
import { getUserAbtData } from '@shein-aidc/basis-abt-router' // 引入获取abt方法
import i18n from 'public/src/pages/common/biz_helper/i18n'
import RightConfigDialog from './RightConfigDialog.vue'
import { FB_PAGE_ID_MAP } from './constant.js'
import { SPopover } from '@shein-aidc/sui-popover'
import CircleLoading from 'public/src/pages/components/CircleLoading.vue'
import { template, getCookie } from '@shein/common-function'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { abtservice } from 'public/src/services/abt'
import { getQueryString } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'

daEventCenter.addSubscriber({ modulecode: '2-15' })
const { lang, LAYOUT, IS_RW, PUBLIC_CDN, SiteUID, WEB_CLIENT, FB_APPID, appLanguage } = gbCommonInfo || {}
const { page_name = '' } = window.SaPageInfo || {}

function isEmpty (obj) {
  if (obj == null) return true
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const COUPON_GIF_ICON_WATCHED = 'coupon-gif-icon-watched'

const staticCouponSrc = IS_RW 
  ? `${PUBLIC_CDN}/she_dist/images/rowme-right-coupon-0cbc026ef5.png`
  : `${PUBLIC_CDN}/she_dist/images/shein-right-coupon-ea2899b8eb.png`

export default {
  name: 'UpToTop',
  components: {
    suspensionIcon, // 悬浮组件旧/未来会废弃
    floatingIcon, // 悬浮组件新
    RightConfigDialog,
    SPopover,
    CircleLoading,
    Icon
  },
  props: {
    showMore: {
      type: Boolean,
      defualt: true,
    },
    showApp: {
      type: Boolean,
      defualt: true,
    },
    showQuestionnaire: {
      type: Boolean,
      defualt: true,
    },
    firstCouponSite: {
      type: Object,
      default() { return {} }
    },
    appFirstOrderCoupon: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isLoding: false,
      isShowPopover: true,
      messengerShowed: false,
      LAYOUT,
      lang,
      IS_RW,
      language: {},
      PUBLIC_CDN,
      SiteUID,
      WEB_CLIENT,
      page_name,
      cccRightConfig: {},
      couponIconSrc: '',
      fbSdkLoading: false, // 已经正在加载sdk中
      hideFb: 'none', // fb-root真实聊天插件的显隐
      fbIconWrapShow: true, // 聊天插件出来，则需要隐藏自定义的icon
      fbSdkBottom: 0, // 聊天插件需设置底部的距离
      fbRenderSucc: false, // 聊天插件是否渲染成功
      memberId: UserInfoManager.get({ key: 'memberId', actionType: 'footer/up_to_top' }) || '', // 真实memberId
      md5MemberId: '', // memberId加密后
      refContent: '',
      isExposeMsgDetailEnter: false, // 是否上报过了
      isExposeMsgInitFail: false, // 上报过了初始化失败
      iconDataSource: '', // abt 新旧数据源
    }
  },
  computed: {
    floatingIconPreview() {
      return getQueryString({ key: 'contentId' }) && getQueryString({ key: 'pageType' }) === 'floatingIcon'
    },
    // 统一处理优惠券信息
    firstOrderCouponInfo() {
      const { ios = {}, android = {} } = this.appFirstOrderCoupon
      if (
        isEmpty(ios)
        || isEmpty(android)
        || (ios?.coupon_code !== android?.coupon_code) // 返回的券码不一致，则不展示首单券信息
      ) return ''

      const ruleDimension = Number(ios?.rule_dimension || '') // 券类型 1金额 2原价 5应付价 如果rule_dimension=2或者5 则为折扣券，否则为满减券
      return {
        info: [2, 5].includes(ruleDimension) ? `${Number(ios?.discount)}%` : ios?.discount_amount?.amountWithSymbol || '',
        coupon: ios?.coupon_code || ''
      }
    },
    showFirstCouponInfo () {
      return (this.firstOrderCouponInfo?.info && this.firstOrderCouponInfo?.coupon) || !this.filterSite()
    },
    // ccc配置的下载权益弹窗的背景图地址
    cccRightConfigImgUrl() {
      return this.cccRightConfig?.props?.items[0]?.image?.src || ''
    },
    // 是SHEIN && 是首页 && 是us/ph/ca/au
    isShowFacebookMessenger() {
      const showPage = ['page_home', 'page_settings_notification']
      return WEB_CLIENT === 'shein' && showPage.includes(page_name) && (['us', 'ph', 'ca', 'au'].includes(SiteUID))
    },
    // pageId的映射
    fbPageId() {
      return FB_PAGE_ID_MAP[SiteUID || 'us']
    },
  },
  created() {
    this.initBindId()
  },
  async mounted() {

    if(page_name === 'page_settings_notification'){
      this.isShowPopover = false      
    }
    this.language = await i18n.loadByQueue('footer')

    this.getCccRightConfig()
    this.initCouponIconStatus()

    // sa('send', {
    //   activity_name: 'expose_backtotop'
    // })
    const { HomePageFloatingIconExp } = await getUserAbtData()
    // 处理新旧悬浮组件逻辑
    this.iconDataSource = HomePageFloatingIconExp?.p?.DataSource
    this.IfShowComponent = HomePageFloatingIconExp?.p?.IfShowComponent === 'true'
  },
  methods: {
    template,
    getCookie,
    // back to top曝光用
    getAnalysisData(id, { item, index = 0, ...arg } = {}, setCode = '') {
      const data = {
        item,
        index,
        ...arg
      }

      let code = ''
      if (typeof setCode === 'string') code = setCode

      return {
        id,
        code,
        data
      }
    },
    initBindId() {
      this.memberId = UserInfoManager.get({ key: 'memberId', actionType: 'footer/up_to_top' }) || ''
      if(this.memberId) {
        // 不要改掉编码方式,后端需做mid映射
        this.md5MemberId = md5(md5(`messenger_${this.memberId}`))
        let refObj = {
          mid: this.md5MemberId,
          time: String(Date.now())
        }
        this.refContent = window.btoa(JSON.stringify(refObj))
      } else {
        this.refContent = ''
      }
      this.initFbSdkScript()
    },
    postBindRelation() {
      let _isBindMid = window?.sessionStorage?.getItem('_IS_BIND_FB_MESSENGER_MID')
      if(!this.memberId || (this.memberId && _isBindMid)) {
        return
      }
      let data = {
        channel_type: 1, // 渠道类型
        external_id: this.md5MemberId, // 外部扩展id
        external_source: this.fbPageId, // messenger渠道传入facebook的pageId
        member_id: this.memberId, // 会员id
        scene_type: 25, // pc端首页浮标
      }
      schttp({
        method: 'POST',
        url: `/api/user/external/externalIdBind/get`,
        data
      })
        .then(() => {
          window?.sessionStorage?.setItem('_IS_BIND_FB_MESSENGER_MID', true) // 是否此会话已经绑定了mid
        })
    },
    initFbSdkScript() {
      if(!this.isShowFacebookMessenger) return
      // 初始化sdk
      window.TPM?.runImmediate({
        marketing: 'FacebookSDK',
        method: '_getInitFbMessenger',
        params: {
          pageId: this.fbPageId,
          SiteUID,
          appId: FB_APPID,
          refContent: this.refContent,
          dialogShowCb: this.updateWidgetBottom,
          renderCb: this.renderSucc
        }
      })
    },
    updateWidgetBottom() {
      this.exposeClickMessenger('click_messenger_entrance')
      let FB_chatDiaog = document.querySelector('.fb_iframe_widget iframe')
      if(FB_chatDiaog && FB_chatDiaog.style && this.fbSdkBottom) {
        FB_chatDiaog.style.right = '56px'
        FB_chatDiaog.style.bottom = `${this.fbSdkBottom || 0}px`
        FB_chatDiaog.style.maxHeight = `calc(100% - ${this.fbSdkBottom || 0}px)`
      }
    },
    async renderSucc() {
      this.fbRenderSucc = true

      if(page_name === 'page_settings_notification'){
        // eslint-disable-next-line @shein-aidc/abt/abt
        const abtInfo = await abtservice.getUserAbtResult({
          newPosKeys: 'MessengerBind',
        })
        
        if(abtInfo?.MessengerBind?.param?.MessengerBind !== 'on') {
          return
        }
      }
      
      this.exposeClickMessenger('expose_messengericon_entrance')
    },
    enterWrapIcon() {
      // 只上报第一次
      if(this.isExposeMsgDetailEnter) return
      this.exposeClickMessenger('expose_messengerdetail_entrance')
      this.isExposeMsgDetailEnter = true
    },
    fbSdkShow(type) {
      // this.postBindRelation()
      this.isLoding = true
      if(this.messengerShowed) {
        window.FB.CustomerChat.showDialog()
        this.isLoding = false
        return
      }
      if(this.isShowPopover){
        this.postBindRelation()
      }
      if(type === 'icon') {
        this.exposeClickMessenger('click_messengericon_entrance')
      } else if( type === 'detail') {
        this.exposeClickMessenger('click_messengerdetail_entrance')
      }
 
      if(!window.FB) {
        console.error('facebook-messenger init fail.')
        return
      }
      if(this.hideFb === 'none') this.hideFb = 'inline'
      window.FB?.CustomerChat?.show()
      this.fbSdkLoading = true
      this.calculateFbSdkBottom()
      this.fbSetStyle()
    },
    calculateFbSdkBottom() {
      const FE_FB_ICON = document.getElementById('j-fb-warpIcon')
      const windowHeight = window.innerHeight
      const FE_FB_ICON_BOTTOM = FE_FB_ICON.getBoundingClientRect().bottom
      const BOTTOM = windowHeight - FE_FB_ICON_BOTTOM
      this.fbSdkBottom = BOTTOM
    },
    fbSetStyle() {
      let counter = 0
      let setFbIframeStyleTimer = setInterval(() => {
        counter++
        // 获取 .fb_dialog_content 元素的第一个 iframe 子元素
        const FB_iframeEls = document.querySelectorAll('.fb_dialog_content iframe')
        // icon
        let FB_iframeIconEl = FB_iframeEls[0]
        // icon右下角的状态
        let FB_iframeStatusEl = FB_iframeEls[1]
        // icon右上角的未读
        let FB_iframeUnreadEl = FB_iframeEls[2]
        // 聊天弹窗
        let FB_chatDiaog = document.querySelector('.fb_iframe_widget iframe')
        // 修改该元素的 right 和 bottom 样式
        if(!this.isShowPopover) {
          this.fbSdkBottom = 150
        }
        if(FB_iframeIconEl && FB_iframeIconEl.style) {
          FB_iframeIconEl.style.margin = '0 10px'
          FB_iframeIconEl.style.right = '0px'
          FB_iframeIconEl.style.bottom = `${this.fbSdkBottom || 0}px`
        }
        if(FB_iframeStatusEl && FB_iframeStatusEl.style) {
          FB_iframeStatusEl.style.right = '10px'
          FB_iframeStatusEl.style.bottom = `${this.fbSdkBottom || 0}px`
        }
        if(FB_iframeUnreadEl && FB_iframeUnreadEl.style) {
          FB_iframeUnreadEl.style.right = '4px'
          FB_iframeUnreadEl.style.bottom = `${(this.fbSdkBottom + 28) || 0}px`
        }
        if(FB_chatDiaog && FB_chatDiaog.style) {
          window.FB?.CustomerChat?.showDialog()
          FB_chatDiaog.style.right = '56px'
          FB_chatDiaog.style.bottom = `${this.fbSdkBottom || 0}px`
          FB_chatDiaog.style.maxHeight = `calc(100% - ${this.fbSdkBottom || 0}px)`
        }
        let reSetFbIframeStyleFlag = true
        if(FB_iframeIconEl && FB_iframeStatusEl && FB_iframeUnreadEl ) {
          // 隐藏静态的icon
          reSetFbIframeStyleFlag = false
          this.fbSdkLoading = false
          this.isLoding = false
          window.FB?.CustomerChat?.show()
          this.messengerShowed = true
          if(this.isShowPopover){
            this.fbIconWrapShow = false
          }
          this.exposeClickMessenger('expose_messenger_entrance')
          
        }
        if(!reSetFbIframeStyleFlag || counter > 10) {
          clearInterval(setFbIframeStyleTimer)
        }
        if(reSetFbIframeStyleFlag && counter > 10) {
          window.FB?.CustomerChat?.hideDialog()
          this.hideFb = 'none'
          this.fbSdkLoading = false
          if(!this.isExposeMsgInitFail) {
            this.exposeClickMessenger('init_fbsdk')
            this.isExposeMsgInitFail = true
          }
          this.isLoding = false
        }
      }, 1000)
    },
    // messenger 曝光埋点方法
    exposeClickMessenger(name) {
      daEventCenter.triggerNotice({
        daId: '2-15-21',
        extraData: {
          name: name || ''
        },
      })
    },
    // 首单券过滤站点
    filterSite () {
      if (['rwfr', 'rwuk', 'rwca', 'rwmx', 'rwus'].includes(this.SiteUID)) return false
      return true
    },

    // 从ccc获取权益下载弹窗配置
    getCccRightConfig() {
      return schttp({
        url: '/api/ccc/faqInfo/get',
        params: {
          pageType: 'appDlBenefitPopup',
          lang: appLanguage || this.lang,
          siteUid: this.SiteUID,
        },
      }).then((data) => {
        if(data?.code == 0 && data?.info) {
          this.cccRightConfig = data.info.content[0]
        }
      }).catch((err) => {
        console.log('获取下载权益弹窗配置接口失败', err)
      })
    },
    // 鼠标移入后，直到浏览器关闭，都不展示优惠券动画效果
    handleMouseOver() {
      this.couponIconSrc = staticCouponSrc
      if (sessionStorage && !sessionStorage.getItem(COUPON_GIF_ICON_WATCHED)) {
        sessionStorage.setItem(COUPON_GIF_ICON_WATCHED, 'true')
      }
    },
    initCouponIconStatus() {
      if (sessionStorage && sessionStorage.getItem(COUPON_GIF_ICON_WATCHED)) {
        this.couponIconSrc = staticCouponSrc
      } else {
        this.couponIconSrc = IS_RW 
          ? `${PUBLIC_CDN}/she_dist/images/rowme-right-config-coupon-648c2fe5f0.gif`
          : `${PUBLIC_CDN}/she_dist/images/shein-right-config-coupon-82b37f3015.gif`
      }
    }
  }
}
</script>
<style lang="less">
@import './style/Content.less';
.sh_pc_sui_icon_more_up_24px_1 {  /* stylelint-disable-line */
   display: inline-block;
   color: #fff;
   cursor: pointer;
   background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yMCAxNS4zTDEyIDcuMzAwMDVMNCAxNS4zTDQuNzAwMDEgMTZMMTIgOC44MDAwNUwxOS4zIDE2TDIwIDE1LjNaIiBmaWxsPSIjMUMxQzFDIj4KPC9wYXRoPgo8L3N2Zz4=) no-repeat;
   background-size: 100%;
   width: 24px;
   height: 24px;
 }
.she-mask-white {
    z-index: @zindex-modal-background;
    position: fixed;
    top: 0;
    right: 0 /*rtl:ignore*/;
    bottom: 0;
    left: 0 /*rtl:ignore*/;
    background-color: fade(#222, 20%);
}
.she-mask-white {
    background-color: fade(#fff, 90%);
}

.c-back-wrap{
  & > div {
    margin-bottom: 4px;
  }
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 3000;
  display: none;
	.back-wrap-show {
    .coupon-icon {
      background: linear-gradient(#FFDDCA 100%, #FFEBCC 100%);
     img {
       width: 30px;
       height: auto;
     }
    }
		&:hover {
			.right-config-dialog {  /* stylelint-disable-line */
				display: block;
			}
		}
		.right-config-dialog {
			position: fixed;
      display: none;
      right: 40px;
      background: transparent;
      border-radius: 4px; 
      .padding-r(10px);
      bottom: -76px;
			.right-bg-img {  /* stylelint-disable-line */
        width: 271px;
        height: 311px;
			}
		}
	}
}
[lang="arg"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    line-height: 41px;
    .percent-first {  /* stylelint-disable-line */
      font-size: 18px!important;  /* stylelint-disable-line */
    }
    .percent-sec {  /* stylelint-disable-line */
      font-size: 12px!important;  /* stylelint-disable-line */
    }
  }
}
[lang="br"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    line-height: 40px;
    .percent-first {  /* stylelint-disable-line */
      font-size: 23px!important;  /* stylelint-disable-line */
    }
    .percent-sec {  /* stylelint-disable-line */
      font-size: 12px!important;  /* stylelint-disable-line */
    }
  }
}
[lang="cl"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    .percent-first { /* stylelint-disable-line */
      font-size: 26px!important; /* stylelint-disable-line */
    }
    .percent-sec {  /* stylelint-disable-line */
      font-size: 12px!important;  /* stylelint-disable-line */
    }
  }
}
[lang="es"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    .percent-first {  /* stylelint-disable-line */
      font-size: 28px!important; /* stylelint-disable-line */
    }
    .percent-sec { /* stylelint-disable-line */
      font-size: 12px!important;  /* stylelint-disable-line */
    }
  }
}
[lang="fr"] {
  .c-back-wrap.cafr-c-back-wrap .back-wrap-show .show-info .er-percent .percent-first {  /* stylelint-disable-line */
    font-size: 22px;
  }
  .c-back-wrap .back-wrap-show .show-info .er-percent-ma {  /* stylelint-disable-line */
    white-space: nowrap;
  }

  .c-back-wrap .back-wrap-show .show-info .er-percent-ma .percent-first {  /* stylelint-disable-line */
    font-size: 24px;
    padding-right: 0;
  }
}
[lang="id"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    line-height: 40px;
    .percent-first {  /* stylelint-disable-line */
      font-size: 20px; 
    }
    .percent-sec {  /* stylelint-disable-line */
      font-size: 12px;
    }
  }
}
[lang="it"], [lang="ru"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    .percent-first {  /* stylelint-disable-line */
      font-size: 23px!important;  /* stylelint-disable-line */
    }
    .percent-sec { /* stylelint-disable-line */
      font-size: 12px!important;  /* stylelint-disable-line */
    }
  }
}
[lang="pl"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    line-height: 37px;
    .percent-first {  /* stylelint-disable-line */
      font-size: 34px; 
    }
    .percent-sec {  /* stylelint-disable-line */
      font-size: 12px;
      right: 8px;
    }
  }
}
[lang="se"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    line-height: 40px;
    .percent-first {  /* stylelint-disable-line */
      font-size: 24px;
    }
    .percent-sec {  /* stylelint-disable-line */
      right: 7px;
    }
  }
}
[lang="tw"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    line-height: 39px;
    .percent-first {  /* stylelint-disable-line */
      font-size: 24px;
    }
    .percent-sec {  /* stylelint-disable-line */
      right: 7px;
    }
  }
}
[lang="vn"] {
  .c-back-wrap .back-wrap-show .show-info .show-info-ctn .show-info-txt .er-percent {  /* stylelint-disable-line */
    line-height: 40px;
    .percent-first {  /* stylelint-disable-line */
      font-size: 20px; 
    }
    .percent-sec {  /* stylelint-disable-line */
      font-size: 12px;
    }
  }
}
[lang="in"] {
  .c-back-wrap .back-wrap-show .show-info .er-percent .percent-first{  /* stylelint-disable-line */
    font-size: 23px!important;  /* stylelint-disable-line */
  }
}
[lang="mx"] {
  .c-back-wrap .back-wrap-show .show-info .er-percent .percent-first{  /* stylelint-disable-line */
    font-size: 24px!important;  /* stylelint-disable-line */
  }
  .c-back-wrap .back-wrap-show .show-info p.code-app{  /* stylelint-disable-line */
    width: 148px;
    margin-left: 78px;
    text-transform: none;
  }
}
[lang="tr"] {
  .c-back-wrap .back-wrap-show .show-info .er-percent{  /* stylelint-disable-line */
    line-height: 40px;
  }
  .c-back-wrap .back-wrap-show .show-info .er-percent .percent-first{  /* stylelint-disable-line */
    font-size: 24px;
  }
}
.c-video {
  display: none;
  .content-close {
    top: -35px;
    right: -35px;
    font-size: 20px;
    position: absolute;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}
.fb-messenger-popover {
  .sui-popover__content-arrow {
    border-color: #F0F0FF;
  }
}
.c-fb-popover {
  width: 261px;
  min-height: 200px;
  background-image: url('/she_dist/images/fb-messenger/dialog-bg-429c93094d.png');
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'SF UI Text';
  &__wrapIcon {
    width: 40px;
    height: 40px;
    margin-bottom: 2px;
    background-color: #fff;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  &__logo {
    width: 42px;
    height: 42px;
    margin-top: -18px;
  }
  &__title {
    font-weight: 600;
    color: #000;
    font-size: 20px;
    line-height: 24px;
    padding: 8px 0px 18px;
  }
  &__item {
    width: 170px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  &__text {
    font-size: 11px;
    color: #000;
    text-align: left;
  }
  &__icon {
    width: 24px;
    height: 22px;
    margin-right: 4px;
  }
  &__btn {
    width: 152px;
    height: 34px;
    margin: 0 auto 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    color: #000;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0.4000000059604645px 1px 1px 0px rgba(155, 165, 255, 0.50);
  }
  &__right {
    margin-left: 4px;
    width: 14px;
    height: 14px;
  }
}
.suspension-iconWrap {
  position: relative;
}
.code-app {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px auto 0 auto;
  span{
    border: 1px dashed @color_brand;
    padding: 5px 10px;
    display: inline-block;
		color: @color_brand;
  }
}

[lang="ar"] .code-app {
  font-size: 18px;
}
[lang="arg"] .code-app {
  font-size: 12px;
}
[lang="cl"] .code-app {
  font-size: 12px;
}

.c-back-wrap .back-wrap-show .show-info {
    background: transparent;
    width: 325px;
    position: fixed;
    bottom: 8px;
    right: 42px;
    padding-left: 10px;
    padding-right: 20px;
    padding-top: 10px;
    display: none;
}
</style>
