<template>
  <div
    v-if="item"
    class="c-suspension__main j-suspension-main"
    :style="{'visibility': show ? '' : 'hidden', 'background-image': `url(${transformImg(item.imgSrc)})`}"
    da-event-click="2-14-1"
    :data-imp-list="cccPublic && cccPublic.gaName"
    :data-ga-channelname="channelName"
    :data-sa-abtinfo="sa_data"
    :data-aod-id="aodId"

    :data-position-index="1"
    :data-spm="buildImgParams(propData, item, 0)"
    :data-href-tag="item.hrefTypeTag"
    :data-href-target="item.hrefTarget"
    :data-href-type="item.hrefType"
    :data-href-type-two="item.hrefType2"

    :data-scene-name="sceneName"
    :data-operation-component-name="propData.content && propData.content.name"
    :data-operation-name="propData.oper_name"
    @click="clickImageUrl(item)"
  >
    <div
      v-show="closeAuth"
      class="c-suspension__close"
      @click.stop="close"
    >
      <i>
        <Icon
          name="sui_icon_closed_14px_1"
          size="14px"
        />
      </i>
    </div>
  </div>
</template>

<script>
import { CCC_SA_JUMP_TYPE } from 'public/src/pages/common/analysis/ccc_config_utils.js'
import { getCccCateInfo, CccInit } from 'public/src/pages/common/ccc_public_v2'
import { daEventCenter } from 'public/src/services/eventCenter'
import schttp from 'public/src/services/schttp'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { Icon } from '@shein-aidc/icon-vue3'
import {
  getDOMNode,
  hasNode,
  getFromNodeDataset,
  getNodeAttribute,
  changeNodeClassList,
} from '@/common/dom-utils'

daEventCenter.addSubscriber({ modulecode: '2-14' })

export default {
  components: {
    Icon
  },
  data () {
    const hasJOptimizeCategory = hasNode('.j-optimize-category')
    return {
      transformImg,
      show: false,
      closeAuth: false,
      // j-optimize-category为新导航，则取All(10)频道下配置的悬浮
      channelId: hasJOptimizeCategory
        ? 10
        : (getFromNodeDataset('.j-nav-first-cate.cate-active', 'channel_type') || 0),
      channelName: hasJOptimizeCategory
        ? 'All' 
        : (
          (getNodeAttribute('.j-nav-first-cate.cate-active', 'href')
            ?.split(gbCommonInfo.host + '/')[1]
            ?.split('?')[0]
          ) 
          || (gbCommonInfo.IS_RW ? 'girls' : 'all')
        ),

      propData: {},
      item: {},
      cccCateInfo: {},
      cccPublic: null,

      abtInfo: {}
    }
  },
  computed: {
    sa_data() {
      let saAbt = []
      this.abtInfo.child && this.abtInfo.child.length && this.abtInfo.child.forEach(_ => {
        saAbt.push(`${this.abtInfo.poskey}\`${_.eid}\`${_.bid}`)
      })
      return saAbt.join(',')
    },
    aodId() {
      return this.propData?.content?.props?.style?.aod_id || 0
    },
    sensors_data () {
      let test_content = []
      this.abtInfo.child && this.abtInfo.child.length && this.abtInfo.child.forEach(_ => {
        test_content.push([
          `TP_${this.abtInfo.poskey}`,
          `BT_${_.type}`,
          `BI_${_.bid}`,
        ].join('-'))
      })
      return {
        'resource_type': `ON_${this.propData.oper_name},CN_${this.propData.content?.name},HZ_0,HI_0`,
        'resource_content': `${this.item.hrefTypeTag || this.item.hrefTypeTag2 || 0}_${this.item.hrefTarget || 0}`,
        'aod_name': this.propData?.content?.props?.style?.aod_id || 0,
        // 'user_group_id': this.propData.tag_id || 0,
        'test_content_1': test_content.join(',')
      }
    },
    sceneName() {
      return this.channelName.charAt(0).toUpperCase() + this.channelName.slice(1).toLowerCase() + 'HomePage'
    }
  },
  async mounted () {
    if (window.sessionStorage?.getItem('suspension-close')) return
    await this.refreshData()
    if (window.sessionStorage?.getItem('suspension-clicked')) return
    this.bounce()
  },
  methods: {
    async fetchData () {
      const self = this
      const { CccAppIcon } = await getUserAbtData()
      const abtInfo = {
        'CccAppIcon': CccAppIcon || {}
      }
      this.abtInfo = CccAppIcon || {}
      // {
      //   poskey: abtInfo?.CccAppIcon?.poskey,
      //   abtexp: abtInfo?.CccAppIcon?.expid,
      //   abtbranch: abtInfo?.CccAppIcon?.id,
      //   type: abtInfo?.CccAppIcon?.type
      // }

      const abtParam = encodeURIComponent(this.abtInfo?.param)
      await new Promise((resolve) => {
        schttp({
          url: `/api/ccc/icon/query?channelId=${this.channelId}&abtBranch=${abtParam}`,
        }).then(res => {
          self.propData = res?.data?.content?.[0]
          self.item = res?.data?.content?.[0]?.content?.props?.items?.[0]
          self.closeAuth = res?.data?.content?.[0]?.content?.props?.style?.closeAuth === '1'
  
          if (!self.propData || !self.item) return resolve()
  
          getCccCateInfo({
            cccData: [{ operations: [self.propData] || {} }],
            cccOldCateInfo: self.cccCateInfo
          }).then(res => {
            self.cccCateInfo = res
          })
          self.cccPublic = new CccInit({
            context: res?.data,
            propData: self.propData,
            index: 1,
            sceneName: this.sceneName,
            tagId: self.propData.tag_id,
            vueContext: self,
            abtGroups: [abtInfo],
            placeholder: `TY=${self.item.hrefTypeTag || self.item.hrefTypeTag2 || 0}_${self.item.hrefTarget || 0}`
          })
          this.fetchHtml()
          resolve()
        })
      })
    },
    fetchHtml() {
      if (this.item.hrefType !== 'activityNew') return
      const { host, SW_FETCH_HTML_CONFIG = {} } = window.gbCommonInfo || {}
      const { enableSceneMap = [], preFetchSceneMap = [] } = SW_FETCH_HTML_CONFIG || {}
      // 是否开启sw页面代理
      if (!enableSceneMap.includes('special')) return
      // 页面是否预取
      if(!preFetchSceneMap.includes('special')) return
      const url = this.cccPublic.getCccLink({
        item: this.item,
        cccCateInfo: this.cccCateInfo,
        gaIndex: 0,
        scIndex: 0
      })
      const requestUrl = `${url}${url.indexOf('?') > -1 ? '&proxy_enable=1' : '?proxy_enable=1' }`
      if (window.SW_Loaded) {
        schttp({
          url: `${host}${requestUrl}`,
          schttp: { apiLogSkip: true }
        })
        return
      }
      window.addEventListener('SW_Loaded', () => {
        schttp({
          url: `${host}${requestUrl}`,
          schttp: { apiLogSkip: true }
        })
      })
    },
    async refreshData () {
      this.show = false
      await this.fetchData()
      if (this.item?.imgSrc) {
        let image = new Image()
        image.src = transformImg(this.item.imgSrc)
        image.onload = () => {
          this.show = true
          this.$nextTick(() => {
            daEventCenter.triggerNotice({
              daId: '2-14-2',
              extraData: {
                target: getDOMNode('.j-suspension-main'),
                channelName: this.channelName,
                eventLabel: this.cccPublic && this.cccPublic.gaName,
                aodId: this.aodId,
              },
            })
          })
        }
      }
    },
    bounce () {
      setInterval(() => {
        const jSuspensionMainNode = getDOMNode('.j-suspension-main')
        changeNodeClassList(
          jSuspensionMainNode,
          { remove: ['bounce'] },
          { add: ['bounce', 'animated-slow'] }
        )
        jSuspensionMainNode.addEventListener('animationend', () => {
          changeNodeClassList(
            jSuspensionMainNode,
            { remove: ['bounce'] }
          )
        }, { once: true })
      }, 3000)
    },
    close () {
      this.show = false
      window.sessionStorage?.setItem('suspension-close', 1)
    },


    clickImageUrl(item) {
      window.sessionStorage?.setItem('suspension-clicked', 1)
      let href = this.cccPublic.getCccLink({
        item,
        cccCateInfo: this.cccCateInfo,
        gaIndex: 0,
        scIndex: 0
      })
      if (this.item?.hrefType === 'activityNew') {
        href = `${href}${href.indexOf('?') > -1 ? '&proxy_enable=1' : '?proxy_enable=1' }`
      }
      let sId = href.indexOf('TY%3D')
      let eId = href.indexOf('ABT%3D')
      if (sId > -1) {
        location.href = href.replace(href.substring(sId, eId), 'PS%3D1-1_')
        return
      }
      let sId1 = href.indexOf('TY=')
      let eId1 = href.indexOf('ABT=')
      if (sId1 > -1) {
        location.href = href.replace(href.substring(sId1, eId1), 'PS=1-1_')
        return
      }
    },
    buildImgParams(content, item, index) {
      const type = item.hrefType === 'special' ? item.hrefType2 : item.hrefType
      return [this.cccPublic && this.cccPublic.saName || '', Number(index) + 1, 0, 0, item.hrefTypeId || CCC_SA_JUMP_TYPE[type], content.content && content.content.id].join('`')
    },
  }
}
</script>
<style lang="less">
.c-suspension {
  &__main {
    width: 36px;
    height: 36px;
    line-height: 36px;
    margin-bottom: 2px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-align: center;
    cursor: pointer;
    position: relative;
  }
  &__close {
    position: absolute;
    .right(0);
    top: 0;
    width: 18px;
    height: 18px;
    transform: translate(48%, -48%) scale(0.7);
    cursor: pointer;
    .icon-close {
      display: inline-block;
      font-size: 12px;
      width: 18px;
      height: 18px;
      line-height: 19px;
      border-radius: 50%;
      background: rgba(102, 102, 102, 0.6);
      color: #fff;
      font-weight: bold;
      vertical-align: top;
    }
  }
  .sh_pc_sui_icon_closed_14px_1 {  /* stylelint-disable-line */
    display: inline-block;
    background: url(data:image/svg+xml;base64,PHN2ZyBzdHlsZT0id2lkdGg6IGF1dG87IGhlaWdodDogMWVtOyIgd2lkdGg9IjE0IiBoZWlnaHQ9IjE0IiB2aWV3Qm94PSIwIDAgMTQgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0yLjc1NzU4IDIuMDUwNTRMMi4wNTA0OCAyLjc1NzY0TDYuMjkyOSA3LjAwMDA3TDIuMDUwNDggMTEuMjQyNUwyLjc1NzU4IDExLjk0OTZMNy4wMDAwMSA3LjcwNzE3TDExLjI0MjQgMTEuOTQ5NkwxMS45NDk1IDExLjI0MjVMNy43MDcxMSA3LjAwMDA3TDExLjk0OTUgMi43NTc2NEwxMS4yNDI0IDIuMDUwNTRMNy4wMDAwMSA2LjI5Mjk2TDIuNzU3NTggMi4wNTA1NFoiIGZpbGw9IiMxQzFDMUMiPgo8L3BhdGg+Cjwvc3ZnPg==) no-repeat;
    background-size: 100%;
    width: 14px;
    height: 14px;
  }
}
</style>
