<!-- eslint-disable @shein-aidc/cccx/notUseImgTag -->
<template>
  <div class="base-img">
    <a
      ref="baseLinkRef"
      :href="url"
      :style="getImgPlaceholder()"
      class="base-img__href"
      role="link"
      :tabindex="0"
      :aria-label="ada"
      @click.prevent="clickImg"
    >
      <img
        v-if="firstScreen || sceneData.pageType === 'topBanner'"
        :style="{ objectPosition: position }"
        class="base-img__inner fsp-element"
        :class="`base-img__${sceneData.pageType === 'topBanner' ? 'cover' : fit}`"
        :src="baseCutImg"
        :alt="alt"
        :fetchpriority="lcpImage ? 'high' : 'auto'"
      />
      <img
        v-else
        :style="{ objectPosition: position }"
        class="base-img__inner lazyload"
        :class="`base-img__${sceneData.pageType === 'topBanner' ? 'cover' : fit}`"
        :src="EmptyNewImg"
        :data-src="imgSrc"
        :data-design-width="imgDesignWidth"
        :data-exp="imgDataExpStr"
        :alt="alt"
      />
    </a>
  </div>
</template>

<script>
import { EmptyNewImg, imgExpandData2Str } from 'public/src/services/resource/index'
export default {
  name: 'BaseImg',
  props: {
    imgSrc: {
      type: String,
      default: '',
    },
    url: {
      type: [String, Function],
      default: () => 'javascript:;',
    },
    // 裁剪定位
    position: {
      type: String, // 同 object-position
      default: 'initial',
    },
    // 裁剪方式
    fit: {
      type: String, // 'none' | 'contain' | 'cover' | 'fill' | 'scale-down' | 'initial' | 'inherit'
      default: 'initial',
    },
    // 占位图使用方式参考：
    // wiki.pageId=611059170
    placeholder: {
      type: Object,
      default: () => ({
        width: 500,
        height: 500,
        query: '',
      }),
    },
    // 高/宽比率
    ratio: {
      type: [Number, String],
      default: 0,
    },
    ada: {
      type: String,
      default: 'Photo',
    },
    alt: {
      type: String,
      default: '',
    },
    brand: {
      type: String,
      default: 'shein',
    },
    observerImage: {
      type: Boolean,
      default: true
    },
    imgDesignWidth: {
      type: [Number, String],
      default: 0,
    },
    imgDataExp: {
      type: Object,
      default: () => ({})
    },
    firstScreen: {
      type: Boolean,
      default: false
    },
    // 是否为组件列表中最大图片
    lcpImage: {
      type: Boolean,
      default: false
    },
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
    // isFirstPage: {
    //   from: 'isFirstPage',
    //   default: false,
    // },
    sceneData: {
      from: 'sceneData',
      default: () => ({})
    },
    baseImageMounted: {
      from: 'baseImageMounted',
      default: () => () => {}
    }
  },
  data() {
    return {
      hrefUrl: '',
      EmptyImg: '/she_dist/images/bg-grey-solid-color-9328fc4197.png',
      EmptyNewImg
    }
  },
  computed: {
    imgDataExpStr() {
      return imgExpandData2Str(this.imgDataExp)
    },

    baseCutImg() {
      const { pageType } = this.sceneData
      if (!this?.cutImg || pageType === 'topBanner') {
        return this.imgSrc.replace(/^https?\:/, '')
      }

      return this.cutImg(this.imgSrc, this.imgDesignWidth, this.imgDataExp)
    }
  },
  mounted() {
    // ssr绑定的href值和csr不同，ssr时的url没有参数(ccc-link.js中服务端不走setLinkParams方法)
    // 而csr渲染后url并不会再次变更，依然是ssr时的url，所以这里需要手动再次设值，保证url正确
    this.$refs.baseLinkRef.href = this.url
    this.baseImageMounted()
  },
  methods: {
    clickImg() {
      this.$emit('click')
    },
    getImgPlaceholder() {
      const { height, width } = this.placeholder
      return {
        paddingBottom: ((height / width) * 100).toFixed(2) + '%',
      }
    },
  },
  emits: ['click'],
}
</script>

<style lang="less">
.base-img {
  width: 100%;
  position: relative;
  overflow: hidden;
  &__href {
    width: 100%;
    display: block;
  }

  &__inner {
    width: 100%;
    height: 100%;
    vertical-align: top;
    position: absolute;
  }
  &__contain {
    object-fit: contain;
  }
  &__cover {
    object-fit: cover;
  }
  &__scale-down {
    object-fit: scale-down;
  }
  &__initial {
    object-fit: initial;
  }
  &__inherit {
    object-fit: inherit;
  }
}
</style>
