import { Abt } from '@shein-aidc/basis-abt'
import schttp from 'public/src/services/schttp'

export default function createInstance() {
  const abt = new Abt({ schttp: async (data) => {
    try {
      const result = await schttp({
        url: '/api/abt/userinfo/query',
        method: 'post',
        data,
      })
      return result.params
    } catch(e) {
      console.log(e)
      return {}
    }
  } })
  return abt
}
