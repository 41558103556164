/* eslint-disable no-unused-vars */
/* eslint-disable @shein-aidc/common/notDirectUseCookie */

import UserKey from '../UserKey'
import { getCookie } from '@shein/common-function'
import schttp from 'public/src/services/schttp'

const { default_currency, currencies } = gbCommonInfo
const COOKIE_KEY = 'currency'
const SUPPORT_CURRENCIES = Object.keys(currencies || {})

class Currency extends UserKey {
  static canHandle(key) {
    return key === 'currency'
  }

  get() {
    //由于cookie可能是其它站点设置，这里的cookie值必须为支持值，
    let value = default_currency
    //由于这里可能比较早执行，还没有请求到服务，cookie还是没被服务端验证过
    let cookieValue = getCookie(COOKIE_KEY)
    //cookie必须是当前站点支持值
    if( cookieValue && SUPPORT_CURRENCIES.includes(cookieValue) ){
      value = cookieValue 
    }
    return value
  }
  async set(value, { isLog = 0 } = {}) {
    let params = { currency: value }
    if( isLog == 1 ){
      Object.assign(params, {
        isLog: 1,
        gbCur: gbCommonInfo.currency
      })
    }

    await schttp({
      url: '/api/common/currency/update',
      params
    })
    return value
  }
}

export default Currency
