import Scroll from 'public/src/services/expose/index.js'

export default class DaExpose {
  constructor({ publisher, ...arg }) {
    this.scrollInstance = new Scroll(arg, !Boolean(Object.keys(arg).length))
    // window.testDaScrollInstance = this.scrollInstance
    this.publisher = publisher
  }

  /**
   * 订阅绑定曝光元素
   * @param {*} param0
   * @param {String} keycode 容器的标识code eg: listContainerCode`2-3-2|2-2-2,sliderContainerCode`2-3-2
   * @param {String} type 当前code是属于列表类型的吗
   * @param {Boolean} once 当前code是元素是否单次曝光，默认true
   * @param {Boolean} horizontal type为'list'有效，当前列表是横滑类型的列表吗
   * @param {Function} middleJudge 扩展给调用者自己判断的方法，需返回一个布尔值
   * @param {Object} hooks
   *    @param {Function} afterExpose 曝光回调
   */
  subscribe({ keycode = '', type = '', horizontal = false, once = true, middleJudge = null, hooks = {} }) {
    const codes = keycode.split(',')
    if (!codes.length) return

    let section = []
    let elements = []
    codes.forEach(key => {
      const keys = key.split('`')
      // 1. 如果没有传入容器code
      if (!keys[0]) return console.warn(`da expose: [${key}] missing subscribe code`)

      // 2. 如果没有传入曝光的id
      if (keys.length <= 1) return console.warn(`da expose: subscriber code [${keys[0]}] missing expose id`)

      const target = keys[1].split('|').map(exId => `[da-event-expose='${exId}']`)
      type == 'list' ? section.push({
        code: keys[0],
        container: `[da-expose-code=${keys[0]}]`,
        target: target.join(),
        averageContent: false,
        horizontal
      }) : elements.push({
        code: keys[0],
        target: target.map(i => {
          return `[da-expose-code=${keys[0]}] ${i}`
        }).join(),
      })
    })

    // 如果都没有需要订阅的选择器，则返回
    if (!section.length && !elements.length) return
    this.scrollInstance.observe({
      section,
      elements,
      middleJudge,
      once
    }, ({ exposeDoms }) => {
      let reportData = {}
      exposeDoms.forEach(item => {
        if (!item) return
        const id = item.getAttribute('da-event-expose')
        let data = reportData[id] || []
        data.push(item)
        reportData[id] = data
      })
      for (const daId in reportData) {
        const targets = reportData[daId]
        hooks?.afterExpose && typeof hooks.afterExpose == 'function' && hooks.afterExpose({ targets, daId })
        this.publisher.notify({ daId, sendType: 'event', target: targets })
      }
    })
  }

  /**
   * 指令注册曝光
   * @param {*} id 曝光的id
   * @param {Object} params 
   *    @param {Array} section 
   *    @param {Array} elements 
   *    @param {Function} middleJudge 
   *    @param {Boolean} once 
   */
  directiveSubscribe(id, params) {
    this.scrollInstance.observe(params, ({ exposeDoms }) => {
      /**
       * {
       *  id: {
       *    doms: [],
       *    binding: []
       *  }
       * }
       */
      let reportData = {}
      exposeDoms.forEach(item => {
        if (!item) return
        let data = reportData[id] || { doms: [], binding: [] }
        data.doms.push(item)
        data.binding.push(item.__binding_ep)
        reportData[id] = data
      })
      for (const daId in reportData) {
        const data = reportData[daId]
        this.publisher.notify({ daId, sendType: 'event', target: data.doms, bindData: data.binding, })
      }
    })
  }

  reset(code) {
    this.scrollInstance.reset(code)
  }
  resetAll(hook) {
    this.scrollInstance.resetAll(hook)
  }
  triggerScroll() {
    this.scrollInstance.triggerScroll()
  }
  update(code) {
    this.scrollInstance.update(code)
  }
  destroy() {
    this.scrollInstance.destroy()
  }
  remove(code) {
    this.scrollInstance.remove(code)
  }

}
