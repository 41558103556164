<template>
  <div
    v-if="showComp && carouselList?.length"
    class="home-floatingIcon__wrap"
  >
    <div
      class="home-floatingIcon__inner"
    >
      <div
        class="home-floatingIcon__closeOuter"
        @click.stop="close"
      >
        <Icon
          class="home-floatingIcon__close"
          name="sui_icon_hatch_close_16px"
          size="20px"
        />
      </div>

      <div @click="handleSlideClick">
        <swiper-container
          ref="floatingIconSwiper"
          init="false"
          class="home-floatingIcon__swiper"
        >
          <template
            v-for="(item, i) in carouselList"
            :key="i"
          >
            <swiper-slide
              v-tap="getAnalysisData('2-19-2', { item, index: i })"
              v-expose="getAnalysisData('2-19-1', { item, index: i })"
              class="home-floatingIcon__slide"
            >
              <BaseImg 
                ref="FloatTransformImgRef"
                fit="cover"
                :placeholder="{
                  width: 52,
                  height: 65,
                }"
                :ratio="item?.image?.ratio"
                :img-design-width="124"
                :img-src="item?.image?.src"
                :first-screen="true"
              />
            </swiper-slide>
          </template>
        </swiper-container>
      </div>
    </div>
  </div>
</template>

<script>
import { getQueryString, isNullOrUnDef } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router' // 引入获取abt方法
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
import {
  getFromNodeDataset,
  getNodeAttribute,
  hasNode,
} from '@/common/dom-utils'

typeof window !== 'undefined' && register()
const { IS_RW } = gbCommonInfo
export default {
  components: {
    Icon,
    BaseImg,
  },
  directives: { tap, expose },
  mixins: [mixins],
  data () {
    return {
      IS_RW,
      index: 0, 
      showComp: false,
      isPreviewContentId: getQueryString({ key: 'contentId' }) || false, // 是否预览标识
      channelId: 10,
      channelName: 'all',
      loadImg: {},
      // 初始配置
      options: {
        direction: 'vertical',
        loop: true,
        speed: 1000,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swipe
        autoplayOutsideControl: false,
        spaceBetween: 0,
      },
      reqContext: {},
      reqPropData: {},
      activeIndex: 0,
      carouselList: [],
      // carouselList: [{ 'gameType': '', 'customIds': '', 'goodsIds': '', 'articleType': '', 'hrefTarget': '', 'hrefType': 'flashSale', 'startTime': null, 'hrefTitleMaterialId': null, 'ada': '', 'image': { 'src': 'https://img.ltwebstatic.com/images3_ccc/2023/12/22/29/1703232620a74142830c951a34d3470a7d15ef247b.gif', 'width': 1077, 'height': 440, 'ratio': 2.45 }, 'hrefTitle': '', 'adp': '', 'weights': '', 'categoryType': '1', 'hrefTitleCopywritingGroupId': null, 'categoryIds': '', 'hrefTitleCopywritingList': [], 'mallCodes': '', 'endTime': null }, { 'gameType': '', 'customIds': '', 'goodsIds': '', 'articleType': '', 'hrefTarget': '', 'hrefType': 'dailyNew', 'startTime': null, 'hrefTitleMaterialId': null, 'ada': '', 'image': { 'src': 'https://img.ltwebstatic.com/images3_ccc/2023/12/21/52/17031451508b8f49d0ed002976c79c75a4c60af0f9.jpg', 'width': 1077, 'height': 440, 'ratio': 2.45 }, 'hrefTitle': '', 'adp': '', 'weights': '', 'categoryType': '1', 'hrefTitleCopywritingGroupId': null, 'categoryIds': '', 'hrefTitleCopywritingList': [], 'mallCodes': '', 'endTime': null }],
    }
  },
  computed: {
    context() {
      return this?.reqContext || {}
    },
    sceneData() {
      return {
        pageType: this?.context?.pageType || '',
        pageFrom: this?.context?.pageType || '',
        sceneName: this?.channelName,
        tabName: this?.channelName,
      }
    },
    propData() {
      return this?.reqPropData || {}
    },
    cateLinks() {
      return this?.reqContext?.cateLinks || {}
    }
    
  },
  async mounted () {
    // j-optimize-category为新导航，则取all(10)频道下配置的悬浮
    const jOptimizeCategorySelector = '.j-optimize-category'
    const elSelector = '.j-nav-first-cate.cate-active'
    if(this.IS_RW) {
      this.channelId = getFromNodeDataset(elSelector, 'channel_type') || 10
      this.channelName = getNodeAttribute(elSelector, 'href')?.split(gbCommonInfo.host + '/')[1]?.split('?')[0] || 'girls'
    } else {
      const hasJOptimizeCategory = hasNode(jOptimizeCategorySelector)
      this.channelId = hasJOptimizeCategory
        ? 10 
        : getFromNodeDataset(elSelector, 'channel_type') || 10
      this.channelName = hasJOptimizeCategory
        ? 'All'
        : (getNodeAttribute(elSelector, 'href')?.split(gbCommonInfo.host + '/')[1]?.split('?')[0]) || 'all'
    }
    if (window.sessionStorage?.getItem('floatingIcon-close')) return
    this.getFloatIconData()
  },
  methods: {
    initSwiper(){
      const swiperEl = this.$refs.floatingIconSwiper
      if (!swiperEl) return
      const swiperParam = {
        modules: [Autoplay],
        on: {
          slideChangeTransitionEnd: (event) => {
            this.activeIndex = event?.realIndex
          }
        }
      }
      Object.assign(swiperEl, this.options, swiperParam)
      swiperEl.initialize()
    },
    async getFloatIconData() {
      const { HomePageFloatingIconExp, advertising, CCCHomePageDynamic } = await getUserAbtData()
      const acResourceAbtParams = advertising?.p || {}
      const dslAbtParams = CCCHomePageDynamic?.p || {}
      let form = {
        pageType: 'floatingIcon',
        channelId: Number(this.channelId),
        iconAccurateDeliveryABT: HomePageFloatingIconExp?.p?.IfAccurateDelivery || '',
        acResourceAbtParams: JSON.stringify(acResourceAbtParams),
        dslAbtParams: JSON.stringify(dslAbtParams),
      }
      if(this.isPreviewContentId) {
        form.channelId = ''
        form.isPreview = true
        form.contentIds = this.isPreviewContentId
      }
      schttp({
        method: 'POST',
        url: `/api/ccc/floatingIcon/get`,
        data: { form }
      })
        .then((data) => {
          if(data?.code === 0 && data?.content?.content?.length) {
            this.reqContext = data?.content || {}
            const result = data?.content?.content.filter(item => item.styleType === 'IMAGE_CAROUSEL_COMPONENT') || []
            this.reqPropData = result?.[0] || {}
            let items = this.reqPropData?.props?.items || []
            items?.forEach((ele) => {
              ele.FE_SHOWFRAME = true
              if (!isNullOrUnDef(ele.frame) && ele.frame === 1 && HomePageFloatingIconExp?.p?.IfShowFrame1 === 'false') {
                ele.FE_SHOWFRAME = false
              }
              if (!isNullOrUnDef(ele.frame) && ele.frame === 2 && HomePageFloatingIconExp?.p?.IfShowFrame2 === 'false') {
                ele.FE_SHOWFRAME = false
              }
              if (!isNullOrUnDef(ele.frame) && ele.frame === 3 && HomePageFloatingIconExp?.p?.IfShowFrame3 === 'false') {
                ele.FE_SHOWFRAME = false
              }
            })
            this.carouselList = items?.filter(item => item.FE_SHOWFRAME)
            if(this.carouselList?.length <= 1) {
              this.options.loop = false
            }
            this.showComp = true
            setTimeout(()=>{
              this.initSwiper()
            }, 500)
            this.$nextTick(()=>{
              this.cccLink = new CccLink({
                sceneData: this.sceneData,
                propData: this.propData,
                context: this.context,
              })
              this.fetchHtml()
            })
          } else {
            this.showComp = false
          }
        })
        .catch(err => {
          console.log('/api/ccc/floatingIcon/get 接口错误', err)
        })
    },
    handleGetFullLink(item, i) {
      return this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: i,
      })
    },
    fetchHtml() {
      const { host, SW_FETCH_HTML_CONFIG = {} } = window.gbCommonInfo || {}
      const { enableSceneMap = [], preFetchSceneMap = [] } = SW_FETCH_HTML_CONFIG || {}
      // 是否开启sw页面代理
      if (!enableSceneMap.includes('special')) return
      // 页面是否预取
      if(!preFetchSceneMap.includes('special')) return
      this.carouselList.forEach((item, i) => {
        if (item?.hrefType === 'activityNew') {
          const url = this.handleGetFullLink(item, i)
          if (window.SW_Loaded) {
            schttp({
              url: `${host}${url}`,
              schttp: { apiLogSkip: true }
            })
            return
          }
          window.addEventListener('SW_Loaded', () => {
            schttp({
              url: `${host}${url}`,
              schttp: { apiLogSkip: true }
            })
          })
        }
      })
    },
    handleSlideClick() {
      let realIndex = this.activeIndex || 0
      let item = this.carouselList[realIndex]
      const url = this.handleGetFullLink(item, realIndex)
      if (!url || url.indexOf('javascript:;') > -1) return
      if (item.hrefType === 'sku' && this.mixinLinkTo) {
        this.mixinLinkTo(url)
        return
      }
      location.href = url
    },
    swiperInitEvent(swiper) {
      if (typeof window !== 'undefined') {
        const images = document.querySelectorAll(`.home-floatingIcon__swiper .swiper-slide .base-img__inner`)
        images && images.forEach(_ => {
          // 懒加载图片在循环轮播时，会出现图片不显示的问题，所以在初始化时，需要把不显示图片去掉loaded属性，重新走懒加载
          _.getAttribute('loaded') === 'loading' && _.removeAttribute('loaded')
          _.removeAttribute('data-loaded')
        })
      }
      this.$nextTick(() => {
        typeof observer !== 'undefined' && observer.observe()
        this.options.autoplay && this.swiperLoopAutoplyToNext(swiper)
      })
    },
    swiperLoopAutoplyToNext(swiper) {
      // loop 回到到了第一张图
      const src = this.$refs.FloatTransformImgRef?.[swiper.activeIndex - 1]?.baseCutImg
      if (src) {
        if (this.loadImg[src]) return
        this.loadImg[src] = 'load'
        swiper.stopAutoplay()
        let img = new Image()
        img.onload = () => {
          this.$nextTick(()=>{
            swiper.startAutoplay()
          })
          img = null
        }
        img.src = src
      }
    },
    transitionEnd(swiper) {
      if (this.options.autoplay) this.swiperLoopAutoplyToNext(swiper)
    },
    close () {
      this.carouselList = []
      this.showComp = false
      window.sessionStorage?.setItem('floatingIcon-close', 1)
    },
  }
}
</script>
<style lang="less" scoped>
.home-floatingIcon {
  &__wrap {
  position: relative;
  width: 36px;
  height: 77px; // 12 + 65 = 77px
  }
  &__slide .base-img {
    height: 100%;
  }
  &__slide /deep/ img.base-img__inner {
    left: 0;
  }
  &__inner {
    position: absolute;
    right: 0;
    // left: 0;
    bottom: 0;
    width: 52px;
    height: 100%; // 12 + 65 = 77px
    overflow: hidden;
    text-align: right;
  }
  &__closeOuter {
    position: relative;
    width: 100%;
    height: 12px;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(0, -8px);
    pointer-events: all;
    overflow: hidden;
    cursor: pointer;
  }
  &__close svg {
    margin-top: -5px;
  }
  &__swiper {
    width: 100%;
    height: 65px;
    cursor: pointer;
  }
}
[mir=rtl] {
  .home-floatingIcon__closeOuter {
    transform: translate(-61%, 0) scale(-1,1);
  }
  
}
</style>
