export default {
  pageName: 'page_return_item_new',
  newPosKeys: [
    'returnVideo', 
    'RefundAmountDetails', 
    'showCombineReturn', 
    'gifcardExpirednotice',
    'accountRequired',
  ],
  posKeys: [],
}
