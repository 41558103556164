import { daEventCenter, setImports, isAbcdEvent } from '@shein-aidc/basis-sa-event-center'
import { daEventCenter as abcdDataCenter } from '../eventCenter'
import mapping from './mapping/index.json'

setImports({
  v2(filePath) {
    return import(
    /* webpackChunkName: "event-center-file-" */ /* @vite-ignore */ `./${filePath}`
    )
  },
  getMapping() {
    if(process.env.NODE_ENV === 'development') {
      try {
        return require('./mapping/index.json')
      } catch (e) {
        return mapping || {}
      }
    }
    
    return mapping || {}
  },
  legacyAbcd: {
    addSubscriber: abcdDataCenter.addSubscriber.bind(abcdDataCenter),
    triggerNotice: abcdDataCenter.triggerNotice.bind(abcdDataCenter),
    getExposeInstance: daEventCenter.getExposeInstance.bind(daEventCenter),
    importAbcdCode(filePath) {
      return import(
        /* webpackChunkName: "event-center-" */ /* @vite-ignore */ `../eventCenter/analysis/config/${filePath}`
      )
    },
  }
})

const v2MixinLayer = {
  addSubscriber(param) {
    const modCodeList = []
    
    if(typeof param === 'string') {
      modCodeList.push(param)
    } else if(Array.isArray(param)) {
      modCodeList.push(...param)
    } else if(typeof param === 'object') {
      const modeCode = param.modCode || param.modulecode
      modeCode && modCodeList.push(modeCode)
    }
    
    modCodeList.filter(Boolean).forEach(modCode => {
      if(isAbcdEvent(modCode)) {
        abcdDataCenter.addSubscriber({ modulecode: modCode })
      } else {
        daEventCenter.addSubscriber({ modCode })
      }
    })
  },
  triggerNotice({ id, daId, extraData, data, ...rest }) {
    const _id = daId || id || ''
    const _extraData =  extraData || data || null
    
    const param = {
      daId: _id,
      extraData: _extraData,
      ...rest
    }
    
    if (!_id) return
    
    // 老方式直接转发
    if (isAbcdEvent(_id)) {
      return abcdDataCenter.triggerNotice(param)
    }
    
    // v2转发
    return daEventCenter.triggerNotice(param)
  }
}

const handler = {
  get: function (obj, prop) {
    return prop in v2MixinLayer ? v2MixinLayer[prop] : daEventCenter[prop]
  }
}

const daProxy = new Proxy({}, handler)

export { daProxy as daEventCenter }
